import React, { useEffect, useState } from 'react'
import { Button, CardBody, Chip, Typography } from '@material-tailwind/react'
import {
  AiFillCalendar,
  AiOutlineDownload,
  AiOutlinePlus,
} from 'react-icons/ai'
import { TfiNewWindow } from 'react-icons/tfi'
import { BsDownload } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem, Select, TextField } from '@mui/material'
import {
  addDomain,
  deleteDomain,
  fetchDomains,
  updateDomain,
} from '../../../store/domains/action'
import DomainModalNew from '../../../components/dashboard/DomainModalNew'
import { fetchCustomers } from '../../../store/customers/action'
import { DatePicker } from '@mui/x-date-pickers'
import SealModal from '../../../components/dashboard/SealModal'
import { CSVLink } from 'react-csv'
import {
  publicImageDownloadUrl,
  publicImageUrl,
} from '../../../contants/config'

export function Domains() {
  const chipColor = {
    Active: 'green',
    Suspended: 'blue-gray',
    Cancelled: 'red',
  }
  const [visible, setVisible] = useState(false)
  const [sealVisible, setSealVisible] = useState(false)
  const [selectedDomain, setSelectedDomain] = useState(null)
  const [filterStatus, setFilterStatus] = useState('All')
  const [filterExpireDate, setFilterExpireDate] = useState('')
  const [searchText, setSearchText] = useState('')
  const domains = useSelector((state) => state.domains.domains)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCustomers())
  }, [])

  useEffect(() => {
    let params = new URLSearchParams()
    if (filterStatus !== '' && filterStatus !== 'All') {
      params.append('status', filterStatus)
    }
    if (filterExpireDate && filterExpireDate !== '') {
      params.append('expire_date', filterExpireDate)
    }
    dispatch(fetchDomains(params.toString()))
  }, [filterStatus, filterExpireDate])

  const handleAddDomain = () => {
    setSelectedDomain(null)
    setVisible(true)
  }

  const handleEditDomain = (domain) => {
    setSelectedDomain(domain)
    setVisible(true)
  }

  const handleDeleteDomain = (domain) => {
    if (
      window.confirm(
        'Are you sure want to delete this domain? This change can not be undone.',
      )
    ) {
      dispatch(deleteDomain(domain.id))
    }
  }

  const handleDownloadCertificate = (domain) => {
    let url = `${publicImageUrl}${domain.certificate}`
    let aTag = document.createElement('a')
    aTag.setAttribute('href', URL.createObjectURL(url))
    aTag.setAttribute('target', '_blank')
    aTag.download = 'certificate.png'
    document.body.appendChild(aTag)
    aTag.click()
    aTag.remove()
  }

  const filteredDomain = () => {
    return domains.filter((domain) => {
      return (
        domain.customer.company_name
          .toLowerCase()
          .search(searchText.toLocaleLowerCase()) !== -1 ||
        domain.main_domain_url
          .toLowerCase()
          .search(searchText.toLocaleLowerCase()) !== -1
      )
    })
  }

  const csvData = () => {
    let result = []
    let filtered = domains.filter((domain) => {
      return (
        domain.customer.company_name
          .toLowerCase()
          .search(searchText.toLocaleLowerCase()) !== -1 ||
        domain.main_domain_url
          .toLowerCase()
          .search(searchText.toLocaleLowerCase()) !== -1
      )
    })
    filtered.forEach((element) => {
      const seal = `<a href="${
        process.env.REACT_APP_API_VERIFICATION_URL
      }validate/page?domain=${
        element?.main_domain_url || ''
      }" target="_blank"><img src="${
        process.env.REACT_APP_API_VERIFICATION_URL
      }api/validate/logo?domain=${
        element?.main_domain_url || ''
      }" alt="Logo" width="100" /></a>`
      result.push({
        id: element.id,
        company_name: element.customer.company_name,
        domain_license_status: element.domain_license_status,
        main_domain_url: element.main_domain_url,
        complaints_email: element.complaints_email,
        expiry_date: element.expiry_date,
        date_brand_was_onboarded: element.date_brand_was_onboarded,
        date_brand_was_deboarded: element.date_brand_was_deboarded,
        seal_code: seal,
      })
    })
    return result
  }

  const headers = [
    { label: 'ID', key: 'id' },
    { label: 'Brand Name', key: 'company_name' },
    { label: 'Domain License Status', key: 'domain_license_status' },
    { label: 'Main Domain URL', key: 'main_domain_url' },
    { label: 'Complaints Email', key: 'complaints_email' },
    { label: 'Expiry Date', key: 'expiry_date' },
    { label: 'Date Brand was Onboarded', key: 'date_brand_was_onboarded' },
    { label: 'Date Brand was Deboarded', key: 'date_brand_was_deboarded' },
    { label: 'Seal Code', key: 'seal_code' },
  ]

  return (
    <div className="mt-1">
      <div className="w-full">
        <Typography variant="h6" color="blue-gray">
          Domain List
        </Typography>
      </div>
      <div className="flex justify-between rounded-md py-1 px-3 items-center">
        <div className="flex items-start pt-2 gap-2">
          <Button
            variant="gradient"
            type="button"
            className="flex items-center gap-1 p-2 rounded-sm flex-none w-30"
            onClick={() => handleAddDomain()}
            fullWidth
          >
            <AiOutlinePlus /> Add New
          </Button>
          <CSVLink data={csvData()} headers={headers} filename="Domain Reports">
            <Button
              variant="gradient"
              type="button"
              className="flex items-center gap-1 p-2 rounded-sm"
              fullWidth
            >
              <AiOutlineDownload /> Export
            </Button>
          </CSVLink>
        </div>
        <div className="flex items-center gap-3">
          <div className="flex flex-col gap-1">
            <label
              htmlFor="domain_search"
              className="text-blue-gray-900 whitespace-nowrap"
            >
              Search
            </label>
            <TextField
              size="small"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              id="domain_search"
              name="domain_search"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="domain_status"
              className="text-blue-gray-900 whitespace-nowrap"
            >
              Domain Status
            </label>
            <Select
              type="text"
              id="domain_status"
              name="domain_status"
              className="w-32"
              size="small"
              defaultValue={'All'}
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <MenuItem value="All">All Status</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Suspended">Suspended</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
            </Select>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="expire_date" className="text-blue-gray-900">
              Expire Date
            </label>
            <DatePicker
              value={filterExpireDate}
              onChange={(newValue) => {
                setFilterExpireDate(newValue?.format('MM/DD/YYYY'))
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  error={false}
                  id="expire_date"
                  name="expire_date"
                />
              )}
              components={{
                OpenPickerIcon: AiFillCalendar,
              }}
              className="w-40"
            />
          </div>
        </div>
      </div>
      <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
        <table className="w-full min-w-[640px] table-auto">
          <thead>
            <tr>
              {[
                'ID',
                'Brand Name',
                'Domain License Status',
                'Main Domain URL',
                'Complaints Email',
                'Expiry Date',
                'Date Brand was Onboarded',
                'Date Brand was Deboarded',
                'Seal',
                'Edit',
                'Delete',
                'Download',
              ].map((el, key) => (
                <th
                  key={key}
                  className="border-b border-blue-gray-50 py-3 pl-5 text-left"
                >
                  <Typography
                    variant="small"
                    className="text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap"
                  >
                    {el}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredDomain().map((domain, key) => {
              const className = `py-3 pl-5 ${
                key === domains.length - 1 ? '' : 'border-b border-blue-gray-50'
              }`

              return (
                <tr key={key}>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {domain.id}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {domain.customer.company_name}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Chip
                      variant="gradient"
                      color={chipColor[domain.domain_license_status]}
                      value={domain.domain_license_status}
                      className="py-0.5 px-2 text-[11px] font-medium"
                    />
                  </td>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {domain.main_domain_url}
                      <br />
                      {domain.last_ping_date || ''}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {domain.complaints_email}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {domain.expiry_date}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {domain.date_brand_was_onboarded}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {domain.date_brand_was_deboarded}
                    </Typography>
                  </td>
                  <td className={className}>
                    <div>
                      <a
                        href="#"
                        className="text-blue-600 whitespace-nowrap flex items-center gap-1"
                        onClick={() => {
                          setSelectedDomain(domain)
                          setSealVisible(true)
                        }}
                      >
                        seal-code
                        <TfiNewWindow />
                      </a>
                      <a
                        href={`${
                          process.env.REACT_APP_API_VERIFICATION_URL
                        }validate/page?domain=${domain?.main_domain_url || ''}`}
                        className="text-blue-600 whitespace-nowrap flex items-center gap-1"
                        target={'_blank'}
                      >
                        cert-preview
                        <TfiNewWindow />
                      </a>
                    </div>
                  </td>
                  <td className={className}>
                    <Button
                      variant="gradient"
                      type="button"
                      className="flex items-center gap-1 p-2 rounded justify-center"
                      onClick={() => handleEditDomain(domain)}
                      fullWidth
                    >
                      Edit
                    </Button>
                  </td>
                  <td className={className}>
                    <Button
                      variant="gradient"
                      color="red"
                      type="button"
                      className="flex items-center gap-1 p-2 rounded justify-center"
                      onClick={() => handleDeleteDomain(domain)}
                      fullWidth
                    >
                      Delete
                    </Button>
                  </td>
                  <td className={className}>
                    <a
                      href={`${publicImageDownloadUrl}${domain['certificate']}`}
                      target="_blank"
                    >
                      <Button
                        variant="gradient"
                        type="button"
                        className="flex items-center gap-1 p-2 rounded justify-center"
                        fullWidth
                      >
                        <BsDownload />
                        Certification
                      </Button>
                    </a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </CardBody>
      <DomainModalNew
        visible={visible}
        onCloseModal={() => setVisible(false)}
        onSubmit={(data) => {
          if (selectedDomain) {
            dispatch(updateDomain(data, selectedDomain.id))
          } else {
            dispatch(addDomain(data))
          }
          setVisible(false)
        }}
        domain={selectedDomain}
      />
      <SealModal
        visible={sealVisible}
        onCloseModal={() => setSealVisible(false)}
        domain={selectedDomain}
      />
    </div>
  )
}

export default Domains
