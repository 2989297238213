import { ADD_CUSTOMER, DELETE_CUSTOMER, FETCH_CUSTOMERS, UPDATE_CUSTOMER } from "../../contants/ActionTypes";

const initialState = {
    customers: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUSTOMERS: {
            return { ...state, customers: action.payload };
        }
        case ADD_CUSTOMER: {
            return { ...state, customers: [...state.customers, action.payload] };
        }
        case UPDATE_CUSTOMER: {
            return { ...state, customers: state.customers.map((e) => e.id === action.payload.id ? action.payload : e) };
        }
        case DELETE_CUSTOMER: {
            return { ...state, customers: state.customers.filter((e) => e.id !== action.payload) };
        }
        default: {
            return { ...state };
        }
    }
};

export { reducer as customerReducer };
