import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createRootReducer } from "./store";

export default function configureStore(
    history,
    initialState
) {
    const store = createStore(
        createRootReducer(history),
        initialState,
        applyMiddleware(routerMiddleware(history), thunk)
    );
    return store;
};
