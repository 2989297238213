import { ADD_DOMAIN, DELETE_DOMAIN, FETCH_DOMAINS, UPDATE_DOMAIN } from "../../contants/ActionTypes";

const initialState = {
    domains: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DOMAINS: {
            return { ...state, domains: action.payload };
        }
        case ADD_DOMAIN: {
            return { ...state, domains: [...state.domains, action.payload] };
        }
        case UPDATE_DOMAIN: {
            return { ...state, domains: state.domains.map((e) => e.id === action.payload.id ? action.payload : e) };
        }
        case DELETE_DOMAIN: {
            return {
                ...state, domains
                    : state.domains
                        .filter((e) => e.id !== action.payload)
            };
        }
        default: {
            return { ...state };
        }
    }
};

export { reducer as domainReducer };
