import { React, useRef } from 'react'
import { VscChromeClose } from 'react-icons/vsc'
import { Modal } from '@mui/material'
import copy from 'copy-to-clipboard'

const SealModal = ({ visible, onCloseModal, domain }) => {
  const seal = `<a href="${
    process.env.REACT_APP_API_VERIFICATION_URL
  }validate/page?domain=${
    domain?.main_domain_url || ''
  }" target="_blank"><img src="${
    process.env.REACT_APP_API_VERIFICATION_URL
  }api/validate/logo?domain=${
    domain?.main_domain_url || ''
  }" alt="Logo" width="100" /></a>`
  const ref = useRef(null)
  const handleCopySeal = () => {
    copy(seal)
    if (ref) {
      ref.current.focus()
    }
  }

  return (
    <Modal
      open={visible}
      onClose={onCloseModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-2xl relative transform rounded-md bg-white p-2 text-left align-middle shadow-xl transition-all max-h-full overflow-auto">
        <div
          as="h3"
          className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
        >
          Seal Code
          <button
            type="button"
            className="outline-none border-none bg-transparent"
            onClick={onCloseModal}
          >
            <VscChromeClose className="text-sm" />
          </button>
        </div>
        <div className="mt-2 p-2">
          <div className="text-base flex items-center gap-1 mb-3">
            <span>Seal Usage</span>
            <button
              className="bg-transparent border-none outline-none cursor-pointer text-blue-500"
              onClick={() => handleCopySeal()}
            >
              Copy
            </button>
          </div>
          <textarea
            ref={ref}
            className="border border-1 border-gray-400 rounded w-full p-2"
            name=""
            id=""
            cols="30"
            rows="10"
            value={seal}
            onFocus={(e) => e.currentTarget.select()}
          ></textarea>
        </div>
      </div>
    </Modal>
  )
}

export default SealModal
