import AppContainer from './container';
import configStore from './configureStore'
import { Provider } from 'react-redux';

const initialState = {}

const store = configStore(initialState)

function App() {
  return (
    <Provider store={store}>
      <AppContainer />
    </Provider>
  );
}

export default App;
