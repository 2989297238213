import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ThemeProvider } from '@material-tailwind/react'
import { MaterialTailwindControllerProvider } from '../context'
import Login from '../pages/login'
import Dashboard from '../layouts/dashboard'
import axios from '../utils/api'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import TestPage from '../pages/Test'
import 'dayjs/locale/fr';
import 'dayjs/locale/ru';
import 'dayjs/locale/de';
import 'dayjs/locale/ar-sa';

const AppContainer = ({ store }) => {
  const token = useSelector((state) => state.auth.token)

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }
// 
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
      <ThemeProvider>
        <MaterialTailwindControllerProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/test" element={<TestPage />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
              <Route path="/pgadmin-1-restrict-bot" element={<Login />} />
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </BrowserRouter>
        </MaterialTailwindControllerProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default AppContainer
