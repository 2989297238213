import { useLocation, Link } from 'react-router-dom'
import {
  Navbar,
  Typography,
  Button,
  IconButton,
  Breadcrumbs,
} from '@material-tailwind/react'
import {
  UserCircleIcon,
  Bars3Icon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/solid'
import { useMaterialTailwindController, setOpenSidenav } from '../../context'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../../store/auth/action'

export function DashboardNavbar() {
  const [controller, dispatch] = useMaterialTailwindController()
  const { fixedNavbar, openSidenav } = controller
  const { pathname } = useLocation()
  const [layout, page] = pathname.split('/').filter((el) => el !== '')
  const appDispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)

  return (
    <Navbar
      color={fixedNavbar ? 'white' : 'transparent'}
      className={`rounded-xl transition-all ${
        fixedNavbar
          ? 'sticky top-4 z-40 py-3 shadow-md shadow-blue-gray-500/5'
          : 'px-0 py-1'
      }`}
      fullWidth
      blurred={fixedNavbar}
    >
      <div className="flex flex-col-reverse justify-between gap-6 md:flex-row md:items-center">
        <div className="capitalize">
          <Breadcrumbs
            className={`bg-transparent p-0 transition-all ${
              fixedNavbar ? 'mt-1' : ''
            }`}
          >
            <Link to={`/${layout}`}>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal opacity-50 transition-all hover:text-blue-500 hover:opacity-100"
              >
                {layout}
              </Typography>
            </Link>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              {page}
            </Typography>
          </Breadcrumbs>
        </div>
        <div className="flex items-center justify-end">
          <IconButton
            variant="text"
            color="blue-gray"
            className="grid xl:hidden"
            onClick={() => setOpenSidenav(dispatch, !openSidenav)}
          >
            <Bars3Icon strokeWidth={3} className="h-6 w-6 text-blue-gray-500" />
          </IconButton>
          <Link to="/auth/sign-in">
            <Button
              variant="text"
              color="blue-gray"
              className="hidden items-center gap-1 px-4 xl:flex normal-case"
            >
              <UserCircleIcon className="h-5 w-5 text-blue-gray-500" />
              {user.name}
            </Button>
          </Link>
          <Button
            variant="text"
            color="blue-gray"
            className="hidden items-center gap-1 px-4 xl:flex normal-case"
            onClick={() => appDispatch(logOut())}
          >
            <ArrowRightOnRectangleIcon className="h-5 w-5 text-blue-gray-500" />
            Sign Out
          </Button>
        </div>
      </div>
    </Navbar>
  )
}

DashboardNavbar.displayName = '/src/widgets/layout/dashboard-navbar.jsx'

export default DashboardNavbar
