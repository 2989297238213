import React from 'react'

export function Home() {
  return (
    <div className="mt-12">
      <a
        className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-3"
        href="http://localhost:8000/validate/page?domain=test.com"
        target={'_blank'}
      >
        Comming Soon
      </a>
    </div>
  )
}

export default Home
