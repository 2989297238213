import { React, useEffect, useState } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { AiFillCalendar } from "react-icons/ai";
import { Button } from "@material-tailwind/react";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, MenuItem, Modal, Select } from "@mui/material";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { publicImageUrl } from "../../contants/config";

const formSchema = Yup.object().shape({
  customer_id: Yup.string().required("Field is require"),
  domain_license_status: Yup.string().required("Field is require"),
  main_domain_url: Yup.string().required("Field is require"),
  complaints_email: Yup.string()
    .email("Valid email is require")
    .required("Field is require"),
  target_markets: Yup.string().required("Field is require"),
  software_platform: Yup.string().required("Field is require"),
  valid_domains: Yup.string(),
  start_date: Yup.string().required("Field is require"),
  expiry_date: Yup.string().required("Field is require"),
  date_brand_was_onboarded: Yup.string().required("Field is require"),
  date_brand_was_deboarded: Yup.string().nullable(),
});

const DomainModalNew = ({ visible, onCloseModal, domain, onSubmit }) => {
  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: {
      customer_id: "",
      domain_license_status: "",
      main_domain_url: "",
      complaints_email: "",
      target_markets: "",
      software_platform: "",
      valid_domains: "",
      start_date: "",
      expiry_date: "",
      date_brand_was_onboarded: "",
      date_brand_was_deboarded: "",
      // customer_id: '1',
      // domain_license_status: 'Active',
      // main_domain_url: 'test.com',
      // complaints_email: 'test@gmail.com',
      // target_markets: '1',
      // software_platform: '1',
      // valid_domains: '1',
      // start_date: '05/11/2023',
      // expiry_date: '05/11/2023',
      // date_brand_was_onboarded: '05/11/2023',
      // date_brand_was_deboarded: '',
    },
    onSubmit: (formData) => {
      console.log("here");
      let submitData = new FormData();
      Object.keys(formData).forEach((key) => {
        submitData.append(key, formData[key]);
      });
      // if (image) {
      //   submitData.append('image', image.file)
      // }
      if (domain) {
        submitData.append("id", domain.id);
        submitData.append("_method", "PATCH");
      }
      onSubmit(submitData);
      console.log("here");
    },
  });
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(false);

  const customers = useSelector((state) => state.customers.customers);

  useEffect(() => {
    if (!visible) {
      formik.resetForm();
      setImageError(false);
      setImage(null);
    } else {
      if (domain) {
        formik.setValues({
          customer_id: domain.customer_id,
          domain_license_status: domain.domain_license_status,
          main_domain_url: domain.main_domain_url,
          complaints_email: domain.complaints_email,
          target_markets: domain.target_markets,
          software_platform: domain.software_platform,
          valid_domains: domain.alias_domains
            .map((e) => (e.original ? e.domain_url : null))
            .filter((e) => e !== null)
            .join("\n"),
          start_date: domain.start_date,
          expiry_date: domain.expiry_date,
          date_brand_was_onboarded: domain.date_brand_was_onboarded,
          date_brand_was_deboarded: domain.date_brand_was_deboarded,
        });
      }
    }
  }, [visible, domain]);

  return (
    <Modal
      open={visible}
      onClose={onCloseModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className="w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-2xl relative transform rounded-md bg-white p-2 text-left align-middle shadow-xl transition-all max-h-full overflow-auto">
        <div
          as="h3"
          className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
        >
          {domain ? "Edit Domain" : "Add Domain"}
          <button
            type="button"
            className="outline-none border-none bg-transparent"
            onClick={onCloseModal}
          >
            <VscChromeClose className="text-sm" />
          </button>
        </div>
        <div className="mt-2">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="customer_id"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Customer
                  </label>
                  <div className="mt-2">
                    <Select
                      type="text"
                      className="w-full"
                      size="small"
                      {...formik.getFieldProps("customer_id")}
                    >
                      {customers.map((customer, index) => (
                        <MenuItem value={customer.id} selected key={index}>
                          {customer.company_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.customer_id &&
                      formik.errors.customer_id && (
                        <div className="text-red-500 text-sm">
                          {formik.errors.customer_id}
                        </div>
                      )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="domain_license_status"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Status
                  </label>
                  <div className="mt-2">
                    <Select
                      type="text"
                      id="domain_license_status"
                      name="domain_license_status"
                      className="w-full"
                      {...formik.getFieldProps("domain_license_status")}
                      size="small"
                    >
                      <MenuItem value="" selected disabled>
                        Select Status
                      </MenuItem>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Suspended">Suspended</MenuItem>
                      <MenuItem value="Cancelled">Cancelled</MenuItem>
                    </Select>
                  </div>
                  {formik.touched.domain_license_status &&
                    formik.errors.domain_license_status && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.domain_license_status}
                      </div>
                    )}
                </div>
                <div>
                  <label
                    htmlFor="main_domain_url"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Main Domain URL
                  </label>
                  <div className="mt-2">
                    <TextField
                      size="small"
                      error={
                        formik.touched.main_domain_url &&
                        formik.errors.main_domain_url
                      }
                      id="main_domain_url"
                      name="main_domain_url"
                      {...formik.getFieldProps("main_domain_url")}
                      className="w-full"
                    />
                  </div>
                  {formik.touched.main_domain_url &&
                    formik.errors.main_domain_url && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.main_domain_url}
                      </div>
                    )}
                </div>
                <div>
                  <label
                    htmlFor="complaints_email"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Complaints Email
                  </label>
                  <div className="mt-2">
                    <TextField
                      size="small"
                      error={
                        formik.touched.complaints_email &&
                        formik.errors.complaints_email
                      }
                      id="complaints_email"
                      name="complaints_email"
                      {...formik.getFieldProps("complaints_email")}
                      className="w-full"
                    />
                  </div>
                  {formik.touched.complaints_email &&
                    formik.errors.complaints_email && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.complaints_email}
                      </div>
                    )}
                </div>
                <div>
                  <label
                    htmlFor="target_markets"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Target Market(s)
                  </label>
                  <div className="mt-2">
                    <TextField
                      size="small"
                      multiline
                      error={false}
                      id="target_markets"
                      name="target_markets"
                      {...formik.getFieldProps("target_markets")}
                      className="w-full"
                      rows={4}
                    />
                  </div>
                  {formik.touched.target_markets &&
                    formik.errors.target_markets && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.target_markets}
                      </div>
                    )}
                </div>
                <div>
                  <label
                    htmlFor="software_platform"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Software/Platform
                  </label>
                  <div className="mt-2">
                    <TextField
                      size="small"
                      multiline
                      rows={1}
                      error={false}
                      id="software_platform"
                      name="software_platform"
                      {...formik.getFieldProps("software_platform")}
                      className="w-full"
                    />
                  </div>
                  {formik.touched.software_platform &&
                    formik.errors.software_platform && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.software_platform}
                      </div>
                    )}
                </div>
                <div className="col-span-1 md:col-span-2">
                  <label
                    htmlFor="valid_domains"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Valid Domains(without "www." or "m." or "nm.")
                  </label>
                  <div className="mt-2">
                    <textarea
                      size="small"
                      id="valid_domains"
                      name="valid_domains"
                      className="w-full rounded h-28 border border-[#bdbdbd] hover:border-[#212121] focus:border-[#1976d2] focus:ring-1 focus:ring-[#1976d2] outline-none px-[14px] py-2"
                      {...formik.getFieldProps("valid_domains")}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="start_date"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Start Date
                  </label>
                  <div className="mt-2">
                    <DatePicker
                      {...formik.getFieldProps("start_date")}
                      onChange={(newValue) => {
                        formik.setFieldValue(
                          "start_date",
                          newValue?.format("MM/DD/YYYY") || ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          error={
                            formik.touched.start_date &&
                            formik.errors.start_date
                          }
                          onBlur={() =>
                            formik.setFieldTouched("start_date", true)
                          }
                        />
                      )}
                      components={{
                        OpenPickerIcon: AiFillCalendar,
                      }}
                      className="w-full"
                    />
                  </div>
                  {formik.touched.start_date && formik.errors.start_date && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.start_date}
                    </div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="expiry_date"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Expiry Date
                  </label>
                  <div className="mt-2">
                    <DatePicker
                      {...formik.getFieldProps("expiry_date")}
                      onChange={(newValue) => {
                        formik.setFieldValue(
                          "expiry_date",
                          newValue?.format("MM/DD/YYYY") || ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          error={
                            formik.touched.expiry_date &&
                            formik.errors.expiry_date
                          }
                          onBlur={() =>
                            formik.setFieldTouched("expiry_date", true)
                          }
                        />
                      )}
                      components={{
                        OpenPickerIcon: AiFillCalendar,
                      }}
                      className="w-full"
                    />
                  </div>
                  {formik.touched.expiry_date && formik.errors.expiry_date && (
                    <div className="text-red-500 text-sm">
                      {formik.errors.expiry_date}
                    </div>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="date_brand_was_onboarded"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Date Brand was Onboarded
                  </label>
                  <div className="mt-2">
                    <DatePicker
                      {...formik.getFieldProps("date_brand_was_onboarded")}
                      onChange={(newValue) => {
                        formik.setFieldValue(
                          "date_brand_was_onboarded",
                          newValue?.format("MM/DD/YYYY") || ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          error={
                            formik.touched.date_brand_was_onboarded &&
                            formik.errors.date_brand_was_onboarded
                          }
                          onBlur={() =>
                            formik.setFieldTouched(
                              "date_brand_was_onboarded",
                              true
                            )
                          }
                        />
                      )}
                      components={{
                        OpenPickerIcon: AiFillCalendar,
                      }}
                      className="w-full"
                    />
                  </div>
                  {formik.touched.date_brand_was_onboarded &&
                    formik.errors.date_brand_was_onboarded && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.date_brand_was_onboarded}
                      </div>
                    )}
                </div>
                <div>
                  <label
                    htmlFor="date_brand_was_deboarded"
                    className="text-gray-700 mb-2 text-sm font-bold"
                  >
                    Date Brand was Deboarded
                  </label>
                  <div className="mt-2">
                    <DatePicker
                      {...formik.getFieldProps("date_brand_was_deboarded")}
                      onChange={(newValue) => {
                        formik.setFieldValue(
                          "date_brand_was_deboarded",
                          newValue?.format("MM/DD/YYYY") || ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          error={
                            formik.touched.date_brand_was_deboarded &&
                            formik.errors.date_brand_was_deboarded
                          }
                          onBlur={() =>
                            formik.setFieldTouched(
                              "date_brand_was_deboarded",
                              true
                            )
                          }
                        />
                      )}
                      components={{
                        OpenPickerIcon: AiFillCalendar,
                      }}
                      className="w-full"
                    />
                  </div>
                  {formik.touched.date_brand_was_deboarded &&
                    formik.errors.date_brand_was_deboarded && (
                      <div className="text-red-500 text-sm">
                        {formik.errors.date_brand_was_deboarded}
                      </div>
                    )}
                </div>
                {domain && (
                  <div>
                    <label
                      htmlFor="#"
                      className="text-gray-700 mb-2 text-sm font-bold"
                    >
                      Certification
                    </label>
                    <Dropzone
                      accept={{
                        "image/png": [".png"],
                      }}
                      disabled
                      multiple={false}
                      onDrop={(files) => {
                        if (files.length > 0) {
                          setImage({
                            file: files[0],
                            preview: URL.createObjectURL(files[0]),
                          });
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <Box
                          className="w-full overflow-hidden rounded-xl border border-dashed border-gray-500 flex items-center justify-center min-h-[80px]"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          {image ? (
                            <img
                              className="w-full"
                              src={image.preview}
                              alt=""
                            />
                          ) : domain ? (
                            <img
                              className="w-full"
                              src={`${publicImageUrl}${domain.certificate}`}
                              alt=""
                            />
                          ) : (
                            <p>Drop files here or click to upload.</p>
                          )}
                        </Box>
                      )}
                    </Dropzone>
                    {imageError && (
                      <div className="text-red-500 text-sm">
                        This field is required
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex justify-end gap-2 mt-10">
                <Button
                  variant="outlined"
                  type="button"
                  color="gray"
                  className="flex items-center gap-1 py-2 px-4 ml-3 rounded justify-center w-fit"
                  fullWidth
                  onClick={() => onCloseModal()}
                >
                  Close
                </Button>
                <Button
                  variant="gradient"
                  type="submit"
                  className="flex items-center gap-1 py-2 px-4 ml-3 rounded justify-center w-fit"
                  fullWidth
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default DomainModalNew;
