export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const REQUEST_ERROR = "request_error";

// authentication constants
export const LOGIN = "login";
export const LOGOUT = "logout";

// customer constants
export const FETCH_CUSTOMERS = 'fetch_customers';
export const ADD_CUSTOMER = 'add_customer';
export const UPDATE_CUSTOMER = 'update_customer';
export const DELETE_CUSTOMER = 'delete_customer';

// customer constants
export const FETCH_DOMAINS = 'FETCH_DOMAINS';
export const ADD_DOMAIN = 'ADD_DOMAIN';
export const UPDATE_DOMAIN = 'UPDATE_DOMAIN';
export const DELETE_DOMAIN = 'DELETE_DOMAIN';