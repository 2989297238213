import axios from "../../utils/api";
import { LOGIN, LOGOUT, FETCH_ERROR, FETCH_START, FETCH_SUCCESS, REQUEST_ERROR, } from "../../contants/ActionTypes";

export const login = ({ email, password }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        return await axios
            .post("auth/login", { email, password })
            .then(({ data }) => {
                axios.defaults.headers.common.Authorization =
                    "Bearer " + data.access_token;
                localStorage.setItem("token", data.access_token);
                dispatch({ type: FETCH_SUCCESS });
                return dispatch({
                    type: LOGIN,
                    payload: data,
                });
            })
            .catch((e) => {
                dispatch({ type: FETCH_ERROR });
                return dispatch({
                    type: REQUEST_ERROR,
                    payload: e.response,
                });
            });
    };
};


export const logOut = () => {
    return async (dispatch) => {
        return await axios
            .post("auth/logout")
            .then(({ data }) => {
                localStorage.removeItem("token");
                localStorage.clear();
                delete axios.defaults.headers.common.Authorization;
                return dispatch({
                    type: LOGOUT,
                });
            })
            .catch((e) => {
                console.log(e);
            });
    };
};