import React, { useEffect, useState } from 'react'
import { Button, CardBody, Chip, Typography } from '@material-tailwind/react'
import { AiOutlineDownload, AiOutlinePlus } from 'react-icons/ai'
import CustomerModal from '../../../components/dashboard/CustomerModal'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCustomer, fetchCustomers } from '../../../store/customers/action'
import { MenuItem, Select, TextField } from '@mui/material'
import { CSVLink } from 'react-csv'

export function Customers() {
  const chipColor = {
    Active: 'green',
    Suspended: 'blue-gray',
    Cancelled: 'red',
  }
  const [visible, setVisible] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [filterStatus, setFilterStatus] = useState('All')
  const [searchText, setSearchText] = useState('')
  const customers = useSelector((state) => state.customers.customers)

  const dispatch = useDispatch()

  function addCustomer() {
    setSelectedCustomer(null)
    setVisible(true)
  }

  function editCustomer(customer) {
    setSelectedCustomer(customer)
    setVisible(true)
  }

  function handleDeleteCustomer(customer) {
    if (
      window.confirm(
        'Are you sure want to delete this customer? This change can not be undone.',
      )
    ) {
      dispatch(deleteCustomer(customer.id))
    }
  }

  useEffect(() => {
    let params = new URLSearchParams()
    if (filterStatus !== '' && filterStatus !== 'All') {
      params.append('status', filterStatus)
    }
    dispatch(fetchCustomers(params.toString()))
  }, [filterStatus])

  const filteredCustomers = () => {
    return customers.filter((domain) => {
      return (
        domain.company_name
          .toLowerCase()
          .search(searchText.toLocaleLowerCase()) !== -1
      )
    })
  }

  const headers = [
    { label: 'Company Name', key: 'company_name' },
    { label: 'Company Number', key: 'company_number' },
    { label: 'Country of Incorporation', key: 'country_of_incorporation' },
    { label: 'Sub-License Reference', key: 'sub_license_reference' },
    { label: 'Company License Status', key: 'company_license_status' },
    { label: 'Company Address', key: 'company_address' },
  ]

  return (
    <div className="mt-1">
      <div className="w-full">
        <Typography variant="h6" color="blue-gray">
          Customer List
        </Typography>
      </div>
      <div className="flex justify-between rounded-md py-1 px-3 items-center">
        <div className="flex items-start pt-2 gap-2">
          <Button
            variant="gradient"
            type="button"
            className="flex items-center gap-1 p-2 rounded-sm w-30"
            onClick={() => addCustomer()}
            fullWidth
          >
            <AiOutlinePlus /> Add New
          </Button>
          <CSVLink
            data={filteredCustomers()}
            headers={headers}
            filename="Customer Reports"
          >
            <Button
              variant="gradient"
              type="button"
              className="flex items-center gap-1 p-2 rounded-sm"
              fullWidth
            >
              <AiOutlineDownload /> Export
            </Button>
          </CSVLink>
        </div>
        <div className="flex items-center gap-3">
          <div className="flex flex-col gap-1">
            <label
              htmlFor="domain_search"
              className="text-blue-gray-900 whitespace-nowrap"
            >
              Search
            </label>
            <TextField
              size="small"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              id="domain_search"
              name="domain_search"
            />
          </div>
          <div className="flex flex-row items-center gap-2">
            <label
              htmlFor="customer_status"
              className="text-blue-gray-900 whitespace-nowrap"
            >
              Customer Status
            </label>
            <Select
              type="text"
              id="customer_status"
              name="customer_status"
              className="w-full min-w-[112px]"
              size="small"
              value={filterStatus}
              defaultValue={'All'}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <MenuItem value="All">All Status</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Suspended">Suspended</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
            </Select>
          </div>
        </div>
      </div>

      <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
        <table className="w-full min-w-[640px] table-auto">
          <thead>
            <tr>
              {[
                'Company Name',
                'Company Number',
                'Country of Incorporation',
                'Sub-License Reference',
                'Company License Status',
                'Company Address',
                'Edit',
                'Delete',
              ].map((el, key) => (
                <th
                  key={key}
                  className="border-b border-blue-gray-50 py-3 pl-5 text-left"
                >
                  <Typography
                    variant="small"
                    className="text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap"
                  >
                    {el}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredCustomers().map((customer, key) => {
              const className = `py-3 pl-5 ${
                key === customers.length - 1
                  ? ''
                  : 'border-b border-blue-gray-50'
              }`

              return (
                <tr key={key}>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {customer.company_name}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {customer.company_number}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {customer.country_of_incorporation}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {customer.sub_license_reference}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Chip
                      variant="gradient"
                      color={chipColor[customer.company_license_status]}
                      value={customer.company_license_status}
                      className="py-0.5 px-2 text-[11px] font-medium"
                    />
                  </td>
                  <td className={className}>
                    <Typography className="text-xs font-semibold text-blue-gray-600">
                      {customer.company_address}
                    </Typography>
                  </td>
                  <td className={className}>
                    <Button
                      variant="gradient"
                      type="button"
                      className="flex items-center gap-1 p-2 rounded justify-center"
                      onClick={() => editCustomer(customer)}
                      fullWidth
                    >
                      Edit
                    </Button>
                  </td>
                  <td className={className}>
                    <Button
                      variant="gradient"
                      color="red"
                      type="button"
                      className="flex items-center gap-1 p-2 rounded justify-center"
                      onClick={() => handleDeleteCustomer(customer)}
                      fullWidth
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </CardBody>
      <CustomerModal
        visible={visible}
        onCloseModal={() => setVisible(false)}
        customer={selectedCustomer}
      />
    </div>
  )
}

export default Customers
