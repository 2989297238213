import { combineReducers } from "redux";
import { authReducer } from "./auth/reducer";
import { customerReducer } from "./customers/reducer";
import { domainReducer } from "./domains/reducer";

export const createRootReducer = () =>
    combineReducers({
        auth: authReducer,
        customers: customerReducer,
        domains: domainReducer,
    });
