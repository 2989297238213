import React from 'react'

const TestPage = () => {
  return (
    <div className="w-screen h-screen bg-black p-5">
      <div className="text-gray-300 mb-10">PAGCOR CERTIFICATE Test Page</div>
      <div className="flex gap-4">
        <div className="">
          <a
            href={`${process.env.REACT_APP_API_VERIFICATION_URL}validate/page?domain=pagcor-regulatory.ph`}
          >
            <img
              src={`${process.env.REACT_APP_API_VERIFICATION_URL}api/validate/logo?domain=pagcor-regulatory.ph`}
              className="w-28"
              alt=""
            />
          </a>
        </div>
        <div className="">
          <a
            href={`${process.env.REACT_APP_API_VERIFICATION_URL}validate/page?domain=domain-validator1.com`}
          >
            <img
              src={`${process.env.REACT_APP_API_VERIFICATION_URL}api/validate/logo?domain=domain-validator1.com`}
              className="w-28"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default TestPage
