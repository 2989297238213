import axios from "../../utils/api";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, REQUEST_ERROR, FETCH_CUSTOMERS, ADD_CUSTOMER, UPDATE_CUSTOMER, DELETE_CUSTOMER, } from "../../contants/ActionTypes";

export const fetchCustomers = (params = '') => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        return await axios
            .get("customers?" + params)
            .then(({ data }) => {
                dispatch({ type: FETCH_SUCCESS });
                return dispatch({
                    type: FETCH_CUSTOMERS,
                    payload: data.customers,
                });
            })
            .catch((e) => {
                dispatch({ type: FETCH_ERROR });
                return dispatch({
                    type: REQUEST_ERROR,
                    payload: e.response,
                });
            });
    };
};

export const addCustomer = (formData) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        return await axios
            .post("customers", formData)
            .then(({ data }) => {
                dispatch({ type: FETCH_SUCCESS });
                return dispatch({
                    type: ADD_CUSTOMER,
                    payload: data.customer,
                });
            })
            .catch((e) => {
                dispatch({ type: FETCH_ERROR });
                return dispatch({
                    type: REQUEST_ERROR,
                    payload: e.response,
                });
            });
    };
};

export const updateCustomer = (formData) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        return await axios
            .put(`customers/${formData.id}`, formData)
            .then(({ data }) => {
                dispatch({ type: FETCH_SUCCESS });
                return dispatch({
                    type: UPDATE_CUSTOMER,
                    payload: data.customer,
                });
            })
            .catch((e) => {
                dispatch({ type: FETCH_ERROR });
                return dispatch({
                    type: REQUEST_ERROR,
                    payload: e.response,
                });
            });
    };
};

export const deleteCustomer = (id) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        return await axios
            .delete(`customers/${id}`)
            .then(({ data }) => {
                dispatch({ type: FETCH_SUCCESS });
                return dispatch({
                    type: DELETE_CUSTOMER,
                    payload: id,
                });
            })
            .catch((e) => {
                dispatch({ type: FETCH_ERROR });
                return dispatch({
                    type: REQUEST_ERROR,
                    payload: e.response,
                });
            });
    };
};