import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { Cog6ToothIcon } from '@heroicons/react/24/solid'
import { IconButton } from '@material-tailwind/react'
import {
  Sidenav,
  DashboardNavbar,
  Configurator,
  Footer,
} from '../widgets/layout'
import { useMaterialTailwindController, setOpenConfigurator } from '../context'
import { useSelector } from 'react-redux'
import routes from '../routes'

export function Dashboard() {
  const [dispatch] = useMaterialTailwindController()
  const { user, token } = useSelector((state) => state.auth)

  if (!user || !token) {
    return <Navigate to={'/'} replace></Navigate>
  }

  return (
    <div className="min-h-screen bg-blue-gray-50/50">
      <Sidenav routes={routes} />
      <div className="p-4 xl:ml-72">
        <DashboardNavbar />
        <Configurator />
        <Routes>
          {routes.map(
            ({ layout, pages }) =>
              layout === 'dashboard' &&
              pages.map(({ path, element }) => (
                <Route exact path={path} element={element} />
              )),
          )}
        </Routes>
      </div>
    </div>
  )
}

Dashboard.displayName = '/src/layout/dashboard.jsx'

export default Dashboard
