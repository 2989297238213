import axios from "../../utils/api";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, REQUEST_ERROR, FETCH_DOMAINS, ADD_DOMAIN, UPDATE_DOMAIN, DELETE_DOMAIN, } from "../../contants/ActionTypes";

export const fetchDomains = (params = '') => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        return await axios
            .get("domains?" + params)
            .then(({ data }) => {
                dispatch({ type: FETCH_SUCCESS });
                return dispatch({
                    type: FETCH_DOMAINS,
                    payload: data.domains,
                });
            })
            .catch((e) => {
                dispatch({ type: FETCH_ERROR });
                return dispatch({
                    type: REQUEST_ERROR,
                    payload: e.response,
                });
            });
    };
};

export const addDomain = (formData) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        return await axios
            .post("domains", formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(({ data }) => {
                dispatch({ type: FETCH_SUCCESS });
                return dispatch({
                    type: ADD_DOMAIN,
                    payload: data.domain,
                });
            })
            .catch((e) => {
                dispatch({ type: FETCH_ERROR });
                return dispatch({
                    type: REQUEST_ERROR,
                    payload: e.response,
                });
            });
    };
};

export const updateDomain = (formData, id) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        return await axios
            .post(`domains/${id}`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(({ data }) => {
                dispatch({ type: FETCH_SUCCESS });
                return dispatch({
                    type: UPDATE_DOMAIN,
                    payload: data.domain,
                });
            })
            .catch((e) => {
                dispatch({ type: FETCH_ERROR });
                return dispatch({
                    type: REQUEST_ERROR,
                    payload: e.response,
                });
            });
    };
};

export const deleteDomain = (id) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_START });
        return await axios
            .delete(`domains/${id}`)
            .then(({ data }) => {
                dispatch({ type: FETCH_SUCCESS });
                return dispatch({
                    type: DELETE_DOMAIN,
                    payload: id,
                });
            })
            .catch((e) => {
                dispatch({ type: FETCH_ERROR });
                return dispatch({
                    type: REQUEST_ERROR,
                    payload: e.response,
                });
            });
    };
};