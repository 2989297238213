import React from 'react'
import {
  Card,
  CardBody,
  CardFooter,
  Input,
  Checkbox,
  Button,
  Typography,
} from '@material-tailwind/react'
import { login } from '../../store/auth/action'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import bgImage from '../../assets/image/background-image.jpg'
import { Navigate } from 'react-router-dom'

const formSchema = Yup.object().shape({
  email: Yup.string().required('Email required'),
  password: Yup.string().required('Password required'),
})

const Login = () => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const user = useSelector((state) => state.auth.user)
  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (data) => {
      dispatch(login(data))
    },
  })

  if (token && user) {
    return <Navigate to={'/dashboard/home'}></Navigate>
  }

  return (
    <>
      <img
        src={bgImage}
        className="absolute inset-0 z-0 h-full w-full object-cover"
        alt="background"
      />
      <div className="absolute inset-0 z-0 h-full w-full bg-black/50" />
      <form className="container mx-auto p-4" onSubmit={formik.handleSubmit}>
        <Card className="absolute top-2/4 left-2/4 w-full max-w-[24rem] -translate-y-2/4 -translate-x-2/4">
          <CardBody className="flex flex-col gap-4">
            <Typography
              variant="h3"
              className="text-[#359bef] text-center mb-5"
            >
              Sign In
            </Typography>
            <div className="w-full">
              <Input
                type="email"
                label="Email"
                size="lg"
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-red-500 text-sm">
                  {formik.errors.email}
                </div>
              )}
            </div>
            <div className="w-full">
              <Input
                type="password"
                label="Password"
                size="lg"
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="text-red-500 text-sm">
                  {formik.errors.password}
                </div>
              )}
            </div>
            <div className="-ml-2.5">
              <Checkbox label="Remember Me" />
            </div>
          </CardBody>
          <CardFooter className="pt-0">
            <Button variant="gradient" type="submit" fullWidth>
              Sign In
            </Button>
          </CardFooter>
        </Card>
      </form>
    </>
  )
}

export default Login
