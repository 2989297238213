import { React, useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import { VscChromeClose } from 'react-icons/vsc'
import { AiOutlinePlus } from 'react-icons/ai'
import { countries } from 'country-data'
import { Button, CardBody, Typography } from '@material-tailwind/react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { addCustomer, updateCustomer } from '../../store/customers/action'
import DomainModal from './DomainModal'
import { MenuItem, Modal, Select, TextField } from '@mui/material'
import { Chip } from '@material-tailwind/react'
import axios from '../../utils/api'

const formSchema = Yup.object().shape({
  company_name: Yup.string().required('Field is require'),
  company_number: Yup.string().required('Field is require'),
  country_of_incorporation: Yup.string().required('Field is require'),
  sub_license_reference: Yup.string().required('Field is require'),
  company_license_status: Yup.string().required('Field is require'),
  company_address: Yup.string().required('Field is require'),
})

const CustomerModal = ({ visible, onCloseModal, customer }) => {
  const chipColor = {
    Active: 'green',
    Suspended: 'blue-gray',
    Cancelled: 'red',
  }

  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedDomain, setSelectedDomain] = useState(null)
  const [domains, setDomains] = useState([])
  const [value, setValue] = useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const formik = useFormik({
    validationSchema: formSchema,
    initialValues: {
      company_name: '',
      company_number: '',
      country_of_incorporation: '',
      sub_license_reference: '',
      company_license_status: '',
      company_address: '',
    },
    onSubmit: (data) => {
      if (customer) {
        dispatch(updateCustomer({ ...data, id: customer.id }))
      } else {
        dispatch(addCustomer(data))
      }
      onCloseModal()
    },
  })

  useEffect(() => {
    if (!visible) {
      formik.resetForm()
    } else {
      if (customer) {
        formik.setValues(customer)
        fetchDomains(customer.id)
      }
    }
  }, [visible, customer])

  const handleAddDomain = () => {
    setSelectedDomain(null)
    setModalVisible(true)
  }

  const handleEditDomain = (domain) => {
    setSelectedDomain(domain)
    setModalVisible(true)
  }

  const fetchDomains = async (customer_id) => {
    await axios.get('domains?customer=' + customer_id).then(({ data }) => {
      setDomains(data.domains)
    })
  }

  const handleDeleteDomain = (id) => {
    if (
      window.confirm(
        'Are you sure want to delete this domain? This change can not be undone.',
      )
    ) {
      deleteDomain(id)
    }
  }

  const addDomain = async (formData) => {
    await axios
      .post('domains', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => {
        setDomains([...domains, data.domain])
      })
  }

  const editDomain = async (formData) => {
    await axios
      .post(`domains/${selectedDomain.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(({ data }) => {
        setDomains(
          domains.map((e) => (e.id === data.domain.id ? data.domain : e)),
        )
      })
  }

  const deleteDomain = async (id) => {
    await axios.delete(`domains/${id}`).then(() => {
      setDomains(domains.filter((e) => e.id !== id))
    })
  }

  return (
    <>
      <Modal
        open={visible}
        onClose={onCloseModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div className="w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-4xl relative transform rounded-md bg-white p-2 text-left align-middle shadow-xl transition-all max-h-full overflow-auto">
          <div
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
          >
            {customer ? 'Edit Customer' : 'Add Customer'}
            <button
              type="button"
              className="outline-none border-none bg-transparent"
              onClick={onCloseModal}
            >
              <VscChromeClose className="text-sm" />
            </button>
          </div>
          <div className="mt-2">
            <TabContext value={value}>
              <Tabs
                onChange={handleChange}
                className={'flex space-x-1 rounded bg-blue-500 pt-1 px-1'}
                value={value}
                indicatorColor="transparent"
              >
                <Tab
                  className={`w-fit px-2 rounded-lg rounded-br-none rounded-bl-none py-2.5 text-sm font-medium leading-5 ${
                    value === '1'
                      ? 'text-gray-700 focus:outline-none bg-white'
                      : 'focus:outline-none text-white hover:bg-white/[0.12] hover:text-white'
                  }`}
                  label={'Information'}
                  value={'1'}
                />
                <Tab
                  className={`w-fit px-2 rounded-lg rounded-br-none rounded-bl-none py-2.5 text-sm font-medium leading-5 ${
                    value === '2'
                      ? 'text-gray-700 focus:outline-none bg-white'
                      : 'focus:outline-none text-white hover:bg-white/[0.12] hover:text-white'
                  }`}
                  disabled={!customer}
                  label={'Domains'}
                  value={'2'}
                />
              </Tabs>
              <TabPanel className="p-0" value="1">
                <form onSubmit={formik.handleSubmit}>
                  <div className="p-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label
                          htmlFor="company_name"
                          className="text-gray-700 mb-2 text-sm font-bold"
                        >
                          Company Name
                        </label>
                        <div className="mt-2">
                          <TextField
                            type="text"
                            id="company_name"
                            name="company_name"
                            className="w-full"
                            size="small"
                            {...formik.getFieldProps('company_name')}
                            error={
                              formik.touched.company_name &&
                              formik.errors.company_name
                            }
                          />
                        </div>
                        {formik.touched.company_name &&
                          formik.errors.company_name && (
                            <div className="text-red-500 text-sm">
                              {formik.errors.company_name}
                            </div>
                          )}
                      </div>
                      <div>
                        <label
                          htmlFor="company_number"
                          className="text-gray-700 mb-2 text-sm font-bold"
                        >
                          Company Number
                        </label>
                        <div className="mt-2">
                          <TextField
                            type="text"
                            id="company_number"
                            name="company_number"
                            className="w-full"
                            size="small"
                            {...formik.getFieldProps('company_number')}
                            error={
                              formik.touched.company_number &&
                              formik.errors.company_number
                            }
                          />
                        </div>
                        {formik.touched.company_number &&
                          formik.errors.company_number && (
                            <div className="text-red-500 text-sm">
                              {formik.errors.company_number}
                            </div>
                          )}
                      </div>
                      <div>
                        <label
                          htmlFor="country_of_incorporation"
                          className="text-gray-700 mb-2 text-sm font-bold"
                        >
                          Country of Incorporation
                        </label>
                        <div className="mt-2">
                          <Select
                            type="text"
                            id="country_of_incorporation"
                            name="country_of_incorporation"
                            className="w-full"
                            {...formik.getFieldProps(
                              'country_of_incorporation',
                            )}
                            size="small"
                          >
                            <MenuItem value="" selected disabled>
                              Select Country
                            </MenuItem>
                            {countries.all.map((country, key) => (
                              <MenuItem value={country.name} key={key}>
                                {country.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        {formik.touched.country_of_incorporation &&
                          formik.errors.country_of_incorporation && (
                            <div className="text-red-500 text-sm">
                              {formik.errors.country_of_incorporation}
                            </div>
                          )}
                      </div>
                      <div>
                        <label
                          htmlFor="sub_license_reference"
                          className="text-gray-700 mb-2 text-sm font-bold"
                        >
                          Sub-License Reference
                        </label>
                        <div className="mt-2">
                          <TextField
                            type="text"
                            id="sub_license_reference"
                            name="sub_license_reference"
                            className="w-full"
                            size="small"
                            {...formik.getFieldProps('sub_license_reference')}
                            error={
                              formik.touched.sub_license_reference &&
                              formik.errors.sub_license_reference
                            }
                          />
                        </div>
                        {formik.touched.sub_license_reference &&
                          formik.errors.sub_license_reference && (
                            <div className="text-red-500 text-sm">
                              {formik.errors.sub_license_reference}
                            </div>
                          )}
                      </div>
                      <div>
                        <label
                          htmlFor="company_license_status"
                          className="text-gray-700 mb-2 text-sm font-bold"
                        >
                          Company License Status
                        </label>
                        <div className="mt-2">
                          <Select
                            type="text"
                            id="company_license_status"
                            name="company_license_status"
                            className="w-full"
                            {...formik.getFieldProps('company_license_status')}
                            size="small"
                          >
                            <MenuItem value="" selected disabled>
                              Select Status
                            </MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Suspended">Suspended</MenuItem>
                            <MenuItem value="Cancelled">Cancelled</MenuItem>
                          </Select>
                        </div>
                        {formik.touched.company_license_status &&
                          formik.errors.company_license_status && (
                            <div className="text-red-500 text-sm">
                              {formik.errors.company_license_status}
                            </div>
                          )}
                      </div>
                      <div className="col-span-1 md:col-span-2">
                        <label
                          htmlFor="company_address"
                          className="text-gray-700 mb-2 text-sm font-bold"
                        >
                          Company Address
                        </label>
                        <div className="mt-2">
                          <TextField
                            size="small"
                            multiline
                            rows={1}
                            error={false}
                            id="company_address"
                            name="company_address"
                            {...formik.getFieldProps('company_address')}
                            className="w-full"
                          />
                        </div>
                        {formik.touched.company_address &&
                          formik.errors.company_address && (
                            <div className="text-red-500 text-sm">
                              {formik.errors.company_address}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="flex justify-end gap-2 mt-10">
                      <Button
                        variant="outlined"
                        type="button"
                        color="gray"
                        className="flex items-center gap-1 py-2 px-4 rounded justify-center w-fit"
                        fullWidth
                        onClick={() => onCloseModal()}
                      >
                        Close
                      </Button>
                      <Button
                        variant="gradient"
                        type="submit"
                        className="flex items-center gap-1 py-2 px-4 rounded justify-center w-fit"
                        fullWidth
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </TabPanel>
              <TabPanel className="p-0" value="2">
                <div className="p-4 min-h-[500px]">
                  <div className="">
                    <Button
                      variant="gradient"
                      type="button"
                      className="flex items-center gap-1 p-2 rounded-sm w-fit"
                      onClick={() => handleAddDomain()}
                      fullWidth
                    >
                      <AiOutlinePlus /> Add New
                    </Button>
                  </div>
                  <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                    <table className="w-full table-auto">
                      <thead>
                        <tr>
                          {[
                            'ID',
                            'Brand Name',
                            'Alias Urls',
                            'Start Date',
                            'Expiry Date',
                            'Status',
                            'Edit',
                            'Delete',
                          ].map((el, key) => (
                            <th
                              key={key}
                              className="border-b border-blue-gray-50 py-3 pl-5 text-left"
                            >
                              <Typography
                                variant="small"
                                className="text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap"
                              >
                                {el}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {domains.map((domain, key) => {
                          const className = `py-3 pl-5 ${
                            key === domains.length - 1
                              ? ''
                              : 'border-b border-blue-gray-50'
                          }`

                          return (
                            <tr key={key}>
                              <td className={className}>
                                <Typography className="text-xs font-semibold text-blue-gray-600">
                                  {domain.id}
                                </Typography>
                              </td>
                              <td className={className}>
                                <Typography className="text-xs font-semibold text-blue-gray-600">
                                  {domain.main_domain_url}
                                </Typography>
                              </td>
                              <td className={className}>
                                <Typography className="text-xs font-semibold text-blue-gray-600">
                                  {domain.alias_domains.map((e, key) => {
                                    if (e.original) {
                                      return (
                                        <span key={key}>
                                          {e.domain_url}
                                          <br />
                                        </span>
                                      )
                                    } else {
                                      return null
                                    }
                                  })}
                                </Typography>
                              </td>
                              <td className={className}>
                                <Typography className="text-xs font-semibold text-blue-gray-600">
                                  {domain.start_date}
                                </Typography>
                              </td>
                              <td className={className}>
                                <Typography className="text-xs font-semibold text-blue-gray-600">
                                  {domain.expiry_date}
                                </Typography>
                              </td>
                              <td className={className}>
                                <Chip
                                  variant="gradient"
                                  color={
                                    chipColor[domain.domain_license_status]
                                  }
                                  value={domain.domain_license_status}
                                  className="py-0.5 px-2 text-[11px] font-medium"
                                />
                              </td>
                              <td className={className}>
                                <Button
                                  variant="gradient"
                                  type="button"
                                  className="flex items-center gap-1 p-2 rounded justify-center"
                                  onClick={() => handleEditDomain(domain)}
                                  fullWidth
                                >
                                  Edit
                                </Button>
                              </td>
                              <td className={className}>
                                <Button
                                  variant="gradient"
                                  color="red"
                                  type="button"
                                  className="flex items-center gap-1 p-2 rounded justify-center"
                                  onClick={() => handleDeleteDomain(domain.id)}
                                  fullWidth
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </CardBody>
                </div>
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </Modal>
      <DomainModal
        visible={modalVisible}
        onCloseModal={() => setModalVisible(false)}
        onSubmit={(data) => {
          if (selectedDomain) {
            editDomain(data)
          } else {
            addDomain(data)
          }
          setModalVisible(false)
        }}
        customer={customer}
        domain={selectedDomain}
      />
    </>
  )
}

export default CustomerModal
