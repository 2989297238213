import { FaUsers } from 'react-icons/fa'
import { AiFillHome, AiFillChrome } from 'react-icons/ai'
import Login from '../pages/login'
import Home from '../pages/dashboard/home'
import Customers from '../pages/dashboard/customers';
import Domains from '../pages/dashboard/domains';

const icon = {
    className: "w-5 h-5 text-inherit",
};

export const routes = [
    {
        layout: "dashboard",
        pages: [
            {
                icon: <AiFillHome {...icon} />,
                name: "Dashboard",
                path: "/home",
                element: <Home />,
            },
            {
                icon: <FaUsers {...icon} />,
                name: "Customers",
                path: "/customers",
                element: <Customers />,
            },
            {
                icon: <AiFillChrome {...icon} />,
                name: "Domains",
                path: "/domains",
                element: <Domains />,
            },
        ],
    },
    {
        pages: [
            {
                path: '/',
                name: 'sign in',
                element: <Login />,
            },
            {
                path: 'login',
                name: 'sign in',
                element: <Login />,
            },
        ],
    },
];

export default routes;
